.container {
  width: 100%;
  margin: 50px auto;
  padding: 20px;
  margin-top: 1px solid blue;
  font-family: "Outfit", serif;
}

.anexo_file {
  font-weight: 600;
  color: #000080;
  font-size: 18px;
  text-decoration: underline;
}

.newsItem {
  margin-bottom: 40px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.header img {
  margin-right: 10px;
  width: 24px;
}

.title {
  font-size: 1.8em;
  font-weight: bold;
  color: #000080;
}

/* Meta Info Section */
.metaInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  color: #a2a2a2;
  margin-bottom: 15px;
  font-size: 1rem;
}

.date {
  display: flex;
  align-items: center;
  gap: 4px;
}

.share_social {
  padding: 20px 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.share_social button {
  border-radius: 8px;
  width: 300px;
  height: 40px;
  background-color: #f5f6f7;
}

.whats,
.face {
  transition: all 0.3s ease-in-out;
}

.whats_icon,
.face_icon {
  transition: color 0.3s ease-in-out;
}

.whats:hover {
  background-color: #25d366;
  color: #f5f6f7;
}

.whats:hover .whats_icon {
  color: #f5f6f7;
}

.face:hover {
  background-color: #0866ff;
  color: #f5f6f7;
}

.face:hover .face_icon {
  color: #f5f6f7;
}

.whats_icon {
  color: #25d366;
  font-size: 24px;
}

.face_icon {
  color: #0866ff;
  font-size: 22px;
}

.imageWrapper {
  margin: 15px 0;
  text-align: center;
}

.imageWrapper img {
  width: 100%;
  max-width: 500px;
  border-radius: 8px;
}

.content {
  font-size: 1.1em;
  line-height: 1.6;
  color: #444;
  padding-top: 50px;
}

.content p {
  margin-bottom: 15px;
  text-align: left;
}

.content a {
  display: inline-block;
  margin: 20px 0;
  position: relative;
  color: blue;
  text-decoration: none;
  z-index: 2;
  transition: 0.2s ease-in-out;
  &:hover {
    color: #000080;
    &:before {
      transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transform-origin: left;
      background: url("/public/icons/wave.svg") 0px 50% / 80px 80px repeat-x;
      animation: waving 3s linear infinite;
      transform: scaleX(1);
      @keyframes waving {
        to {
          background-position: 80px 50%, 160px 50%;
        }
      }
    }
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 80px;
    left: 0;
    bottom: -45px;
    z-index: -1;
    transform: scaleX(0);
    transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1);
    transform-origin: right;
    background: url("/public/icons/wave.svg") 0px 50% / 80px 80px repeat-x;
  }
}

/* Video Wrapper Styles */
.videoWrapper {
  margin: 20px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video {
  width: 100%;
  max-width: 700px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Seção de Compartilhamento */
.shareSection {
  margin-top: 20px;
  text-align: center;
}

.whatsappButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #25d366;
  color: white;
  font-size: 1.1em;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 50px;
  text-decoration: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.whatsappButton img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.whatsappButton:hover {
  background-color: #1ebe57;
}

.ajuste {
  width: 50%;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 50px;
}

/* Media Queries */
@media (min-width: 100px) {
  .ajuste {
    width: 100%;
  }
}
